<template>
    <div class="ivv-content">
        <div class="ivv-box">
<!--            <div class="theme-title">-->
<!--                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315153911707.png"/>-->
<!--            </div>-->
            <van-row class="team-list">
                <van-col span="8" v-for="(item,index) in dataList" :key="index">
                    <div>{{item.groupName}}</div>
                    <div>业绩：{{item.perf}}</div>
                </van-col>
            </van-row>
<!--            <div class="theme-logo">-->
<!--                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210316101718988.png"/>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
  export default {
    name: "ClanList",
    data() {
      return {
        dataList: []
      }
    },
    created() {
      this.getList();
    },
    mounted() {
      document.documentElement.style.fontSize = 'calc(100vw / 1488 * 100)';
    },
    methods: {
      getList() {
        this.get("/OnlyOnce/OfflinePKCompetition/GetGroupPerf", {}, 1).then(res => {
          if (res.code === 1) {
            this.dataList = res.response;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .ivv-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom:0;
        font-size: 1.15vw;
        background-color: #b21e22;

        .ivv-box {
            display: flex;
            height: 100%;
            align-items: center;
            background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210316101540503.png") no-repeat left center;
            background-size: 100% 100%;

            .theme-title {
                width: 1.2rem;
                text-align: right;

                img {
                    width: 1.2rem;
                    display: inline-block;
                    margin-left: 0.2rem;
                }
            }

            .theme-logo {
                width: 1.5rem;
                text-align: center;

                img {
                    width: 1.4rem;
                }
            }

            .team-list {
                width: 100%;

                .van-col {
                    div {
                        font-style: italic;
                        font-weight: bold;
                        font-size: 3.0vw;
                        transform: scale(1.0, 4.0);
                        text-shadow: 0.08rem 0.08rem 0.08rem #330000;

                        &:nth-child(1) {
                            color: #ffffcc;
                            letter-spacing: 10px;
                        }

                        &:nth-child(2) {
                            color: #fff;
                            margin-top: 2rem;
                            letter-spacing: 5px;
                        }
                    }
                }
            }
        }
    }
</style>